/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { BuildingOffice2Icon, LifebuoyIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [lang, setLang] = useState('en');
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    setLang(lng);
    i18n.changeLanguage(lng);
  };
  return (
    <div className="">


      <main>
        {/* Header section */}
        <div className="relative isolate overflow-hidden px-6 pt-14 lg:px-8">
          {/* <img
            src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          /> */}
          {/* <svg
            viewBox="0 0 1097 845"
            aria-hidden="true"
            className="hidden transform-gpu blur-3xl sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:w-[68.5625rem]"
          >
            <path
              fill="url(#10724532-9d81-43d2-bb94-866e98dd6e42)"
              fillOpacity=".2"
              d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
            />
            <defs>
              <linearGradient
                id="10724532-9d81-43d2-bb94-866e98dd6e42"
                x1="1097.04"
                x2="-141.165"
                y1=".22"
                y2="363.075"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#776FFF" />
                <stop offset={1} stopColor="#FF4694" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            viewBox="0 0 1097 845"
            aria-hidden="true"
            className="absolute left-1/2 -top-52 -z-10 w-[68.5625rem] -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0"
          >
            <path
              fill="url(#8ddc7edb-8983-4cd7-bccb-79ad21097d70)"
              fillOpacity=".2"
              d="M301.174 646.641 193.541 844.786 0 546.172l301.174 100.469 193.845-356.855c1.241 164.891 42.802 431.935 199.124 180.978 195.402-313.696 143.295-588.18 284.729-419.266 113.148 135.13 124.068 367.989 115.378 467.527L811.753 372.553l20.102 451.119-530.681-177.031Z"
            />
            <defs>
              <linearGradient
                id="8ddc7edb-8983-4cd7-bccb-79ad21097d70"
                x1="1097.04"
                x2="-141.165"
                y1=".22"
                y2="363.075"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#776FFF" />
                <stop offset={1} stopColor="#FF4694" />
              </linearGradient>
            </defs>
          </svg> */}
          <div className="mx-auto max-w-7xl py-24 sm:py-40">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-4xl font-bold tracking-tight text-ternary-dark dark:text-ternary-light sm:text-6xl">{t('content.contentUs.usTitle')}</h2>
              <p className="mt-6 text-lg leading-8 dark:text-gray-300 text-ternary-dark ">
              {t('content.contentUs.usSubTitle')}
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-8">
              <div className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
                <PhoneIcon className="h-7 w-5 flex-none text-indigo-400" aria-hidden="true" />
                <div className="text-base leading-7">
                  <h3 className="font-semibold text-ternary-dark dark:text-ternary-light">{t('content.contentUs.us')}</h3>
                  <p className="mt-2 dark:text-gray-300 text-ternary-dark ">
                  {t('content.contentUs.phoneUs')}
                    <br />
                    <a className="text-ternary-dark dark:text-ternary-light hover:dark:text-gray-300 text-ternary-dark " href="mailto:sales@ugcarabia.com">
                    {t('content.contentUs.emailUs')}
                    </a>
                  </p>
                </div>
              </div>
              {/* <div className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
                <LifebuoyIcon className="h-7 w-5 flex-none text-indigo-400" aria-hidden="true" />
                <div className="text-base leading-7">
                  <h3 className="font-semibold text-ternary-dark dark:text-ternary-light">Support</h3>
                  <p className="mt-2 dark:text-gray-300 text-ternary-dark ">
                    +1 (555) 234-5679
                    <br />
                    <a className="text-ternary-dark dark:text-ternary-light hover:dark:text-gray-300 text-ternary-dark " href="mailto:support@example.com">
                      support@example.com
                    </a>
                  </p>
                </div>
              </div> */}
              <div className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10">
                <BuildingOffice2Icon className="h-7 w-5 flex-none text-indigo-400" aria-hidden="true" />
                <div className="text-base leading-7">
                  <h3 className="font-semibold text-ternary-dark dark:text-ternary-light">{t('content.contentUs.address')}</h3>
                  <p className="mt-2 dark:text-gray-300 text-ternary-dark ">
                  {t('content.contentUs.theAddress')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </main>
    </div>
  )
}
