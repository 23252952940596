import sas from '../../images/ugclogo.PNG';
import { useTranslation } from 'react-i18next';

const AppFooter = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="pt-5 sm:pt-30 pb-8 mt-5 border-t-2 border-primary-light dark:border-secondary-dark">
      <div
        className="
			container
			flex flex-col flex-wrap
			px-4
			py-7
			mx-auto
			md:items-center
			md:flex-row md:flex-nowrap
		  "
			// lg:items-start
      >
        <div className="flex w-2/6 mx-auto text-center md:mx-0 md:text-left">
        {/* <div className="flex-shrink-0 w-64 mx-auto text-center md:mx-0 md:text-left"> */}
          <img src={sas} className="w-36 h-20" alt="Dark Logo" />
          <p className="mt-2 text-sm text-justify text-gray-500 dark:text-white">
            {t('footer.text')}
          </p>
        </div>
        <div className="justify-between w-full mt-4 text-center lg:flex">
          {/* <div className="w-full px-4 lg:w-1/3 md:w-1/2">
            <ul className="mb-8 space-y-2 text-sm list-none">
              <li>
                <a className="text-gray-600 hover:text-gray-800 dark:text-white">
                  {t('footer.support')}
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full px-4 lg:w-1/3 md:w-1/2">
            <ul className="mb-8 space-y-2 text-sm list-none">
              <li>
                <a className="text-gray-600 hover:text-gray-800 dark:text-white">
                  {t('footer.terms')}
                </a>
              </li>
            </ul>
          </div> */}
          <div className="w-full px-4 lg:w-1/3 md:w-1/2">
            <ul className="mb-8 space-y-2 text-sm list-none">
              {/* <li>
                <a className="text-gray-600 hover:text-gray-800 dark:text-white">
                  {t('footer.dapp')}
                </a>
              </li>
              <li>
                <a className="text-gray-600 hover:text-gray-800 dark:text-white">
                  {t('footer.api')}
                </a>
              </li> */}
              {/* <li>
                <a className="text-white p-1.5 rounded-md bg-logo-dark-color dark:bg-logo-light-color hover:text-gray-800">
                  {t('footer.logout')}
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-center -mt-12">
        <p className="mt-2 text-sm text-justify text-gray-500 dark:text-white">
          {t('footer.copyrights')}
        </p>
      </div>
    </footer>
  );
};

export default AppFooter;
