import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Map from '../../pages/Map';
const ProjectSingle = ({ title, category, image }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, delay: 1 }}
      transition={{
        ease: 'easeInOut',
        duration: 0.7,
        delay: 0.15,
      }}
    >
        <div className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-primary-light dark:bg-ternary-dark">

      {/* <div className=" rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"> */}
        {/* <div>
          <img
            src={image}
            className="rounded-t-xl border-none h-xls w-xls object-contain"
            alt="Single Project"
          />
        </div> */}
        <div className="text-center px-4 py-6">
          <p className="font-general-medium text-xl md:text-2xl text-ternary-dark dark:text-ternary-light mb-2">
            {title}
          </p>
          {/* <span className="text-lg text-ternary-dark dark:text-ternary-light">
            {category}
          </span> */}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectSingle;
