import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AppFooter from './components/shared/AppFooterR';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import Indicators from './pages/Indicators';
import Home from './pages/Home';
import { AnimatePresence } from 'framer-motion';
import UseScrollToTop from './hooks/useScrollToTop';
import { GlobleStoreProvider } from './context/GlobleState';
import { useTranslation } from 'react-i18next';
import Map from './pages/Map';
function App() {
  const { i18n } = useTranslation();
  return (
    <AnimatePresence>
      <div
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        className=" bg-secondary-light dark:bg-primary-dark transition duration-300"
      >
        <Router>
          <GlobleStoreProvider>
            <ScrollToTop />
            <AppHeader />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/map" element={<Map />} />
              <Route path="indicators" element={<Indicators />} />
            </Routes>
            <AppFooter />
          </GlobleStoreProvider>
        </Router>
        <UseScrollToTop />
      </div>
    </AnimatePresence>
  );
}

export default App;
