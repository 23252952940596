import React, { useState } from 'react';
import {
  FiMenu,
  FiMoon,
  FiSun,
  FiX,
  FiChevronDown as FiArrowDown,
} from 'react-icons/fi';
import { MdLanguage } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import sass from '../../images/ugcall.PNG';
import { motion } from 'framer-motion';
import Button from '../reusable/Button';
import { useTranslation } from 'react-i18next';
import GlobleStoreContext from '../../context/GlobleState';

const AppHeader = () => {
  const {
    state,
    dispatch,
    setTheme: setThemet,
  } = React.useContext(GlobleStoreContext);
  const [hoverMore, setHoverMore] = useState(false);
  const [hover, setHover] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [drop, setDrop] = useState(false);
  const [lang, setLang] = useState('en');
  const [activeTheme, setTheme] = useThemeSwitcher();

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    setLang(lng);
    i18n.changeLanguage(lng);
  };

  function toggleSubMenuDrop() {
    if (!drop) {
      setDrop(true);
    } else {
      setDrop(false);
    }
  }

  function toggleSubMenuMore() {
    if (!hoverMore) {
      setHoverMore(true);
    } else {
      setHoverMore(false);
    }
  }

  function toggleSubMenu() {
    if (!hover) {
      setHover(true);
    } else {
      setHover(false);
    }
  }

  function toggleMenu() {
    if (!showMenu) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      id="nav"
      className="sm:container sm:mx-auto mb- mb-5 "
    >
      <div className="z-10 max-w-screen-lg xl:max-w-screen-xl block sm:flex sm:justify-between sm:items-center py-6">
        {/* Header menu links and small screen hamburger menu */}
        <div className="flex justify-between items-center px-4 sm:px-0">
          <div>
            <Link to="/">
              {activeTheme === 'dark' ? (
                <img src={sass} className="w-60" alt="Dark Logo" />
              ) : (
                <img src={sass} className="w-60" alt="Dark Logo" />
              )}
            </Link>
          </div>

          {/* Theme switcher small screen */}
          <div
            onClick={() => {
              setTheme(activeTheme);
              dispatch('changeTheme');
              setThemet(activeTheme);
            }}
            aria-label="Theme Switcher"
            className="block sm:hidden ml-0 bg-primary-light dark:bg-ternary-dark p-3 shadow-sm rounded-xl cursor-pointer"
          >
            {activeTheme === 'dark' ? (
              <FiMoon className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl" />
            ) : (
              <FiSun className="text-gray-200 hover:text-gray-50 text-xl" />
            )}
          </div>

          <div
            onClick={() => {
              changeLanguage(lang === 'en' ? 'ar' : 'en');
              dispatch('changeLang');
            }}
            aria-label="Theme Switcher"
            className="block sm:hidden ml-0 bg-primary-light dark:bg-ternary-dark p-3 shadow-sm rounded-xl cursor-pointer"
          >
            {activeTheme === 'dark' ? (
              <MdLanguage className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl" />
            ) : (
              <MdLanguage className="text-gray-200 hover:text-gray-50 text-xl" />
            )}
          </div>

          {/* Small screen hamburger menu */}
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="focus:outline-none"
              aria-label="Hamburger Menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-7 w-7 fill-current text-secondary-dark dark:text-ternary-light"
              >
                {showMenu ? (
                  <FiX className="text-3xl" />
                ) : (
                  <FiMenu className="text-3xl" />
                )}
              </svg>
            </button>
          </div>
        </div>

        {/* Header links small screen */}
        <div
          className={
            showMenu
              ? 'block m-0 sm:ml-4 mt-5 rtl:text-right sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none'
              : 'hidden'
          }
        >
          <Link
            to="/"
            className={`block ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } text-lg text-primary-dark dark:text-ternary-light rounded-md px-5 py-2.5 hover:bg-logo-dark-color hover:text-white dark:hover:text-secondary-light dark:hover:bg-logo-light-color  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark`}
          >
            {t('header.nav.dashboard')}
          </Link>
          <Link
            to="/indicators"
            className={`block ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } text-lg text-primary-dark dark:text-ternary-light rounded-md px-5 py-2.5 hover:bg-logo-dark-color hover:text-white dark:hover:text-secondary-light dark:hover:bg-logo-light-color  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark`}
          >
            {t('header.nav.indicators')}
          </Link>
          <Link
            to="/map"
            className={`block ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } text-lg text-primary-dark dark:text-ternary-light rounded-md px-5 py-2.5 hover:bg-logo-dark-color hover:text-white dark:hover:text-secondary-light dark:hover:bg-logo-light-color  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark`}
          >
            {t('header.nav.news')}
          </Link>
          {/* <div className="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark">
            <span className="font-general-medium sm:hidden block text-left text-md dark:bg-logo-light-color bg-logo-dark-color hover:bg-indigo-600 text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-300 w-24">
              <Button title={t('header.logIn')} />
            </span>
          </div> */}
        </div>

        {/* Header links large screen */}
        <div className="font-general-medium hidden m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none">
          <Link
            to="/"
            className={`block ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } text-lg text-primary-dark dark:text-ternary-light rounded-md px-5 py-2.5 hover:bg-logo-dark-color hover:text-white dark:hover:text-secondary-light dark:hover:bg-logo-light-color  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark`}
            aria-label="About Me"
          >
            {t('header.nav.dashboard')}
          </Link>
          <Link
            to="/indicators"
            className={`block ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } text-lg text-primary-dark dark:text-ternary-light rounded-md px-5 py-2.5 hover:bg-logo-dark-color hover:text-white dark:hover:text-secondary-light dark:hover:bg-logo-light-color  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark`}
            aria-label="Contact"
          >
            {t('header.nav.indicators')}
          </Link>
          <Link
            to="/map"
            className={`block ${
              i18n.language === 'ar' ? 'text-right' : 'text-left'
            } text-lg text-primary-dark dark:text-ternary-light rounded-md px-5 py-2.5 hover:bg-logo-dark-color hover:text-white dark:hover:text-secondary-light dark:hover:bg-logo-light-color  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark`}
            aria-label="Contact"
          >
            {t('header.nav.news')}
          </Link>
        </div>

        {/* Header right section buttons */}
        <div className="hidden sm:flex justify-between items-center flex-col md:flex-row">
          {/* <div className="hidden md:flex ">
            <span className="text-md font-general-medium dark:bg-logo-light-color bg-logo-dark-color hover:bg-indigo-600 text-white shadow-sm rounded-md px-5 py-2.5 duration-300">
              <Button title={t('header.logIn')} />
            </span>
          </div> */}

          {/* Theme switcher large screen */}
          <div
            onClick={() => {
              setTheme(activeTheme);
              setThemet(activeTheme);
              dispatch('changeTheme');
            }}
            aria-label="Theme Switcher"
            className="ml-8 bg-primary-light rtl:mr-9  dark:bg-ternary-dark p-3 shadow-sm rounded-xl cursor-pointer"
          >
            {activeTheme === 'dark' ? (
              <FiMoon className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl" />
            ) : (
              <FiSun className="text-gray-200 hover:text-gray-50 text-xl" />
            )}
          </div>
          <div
            onClick={() => {
              changeLanguage(lang === 'en' ? 'ar' : 'en');
              dispatch('changeLang');
            }}
            aria-label="Theme Switcher"
            className="ml-8 bg-primary-light dark:bg-ternary-dark p-3 shadow-sm rounded-xl cursor-pointer"
          >
            {activeTheme === 'dark' ? (
              <MdLanguage className="text-ternary-dark hover:text-gray-400 dark:text-ternary-light dark:hover:text-primary-light text-xl" />
            ) : (
              <MdLanguage className="text-gray-200 hover:text-gray-50 text-xl" />
            )}
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default AppHeader;
