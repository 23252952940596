import SingleApi from './SingleApi';
import { useTranslation } from 'react-i18next';

const ProjectsGrid = (props) => {
  const { t, i18n } = useTranslation();

  console.log(props)
  console.log(props)
  return (
    <section className="py-5 sm:py-10 mt-5 sm:mt-10 ">
      <div className="pt-5 sm:pt-30 pb-8 mt-5 border-t-2 border-primary-light dark:border-secondary-dark"></div>

      <div className={`${i18n.language === 'en' ? 'text-left' : 'text-right'}`}>
        <p className="font-general-medium text-2xl sm:text-4xl text-ternary-dark dark:text-ternary-light ">
          {i18n.language === 'en' ? 'Our Products' : 'منتجاتناء'}
        </p>
        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
      </div>
      {!props.loading ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10">
          {props.data.map((img) => (
            <div
            // key={person.email}
            className="relative flex items-center  space-x-3 rounded-lg  bg-primary-light dark:bg-ternary-dark px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
            // className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-secondColorUGC px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="flex-shrink-0">
              <img className="h-10 w-10 rounded-full ml-1" src={img.picture} alt={img.picture} />
            </div>
            <div className="min-w-0 flex-1">
              {/* <a href="#" className="focus:outline-none"> */}
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-ternary-dark dark:text-ternary-light">{img.name}</p>
                <p className="truncate text-sm text-gray-500 dark:text-gray-400" alt={img.dec}>{img.dec}</p>
              {/* </a> */}
            </div>
          </div>
            // <SingleApi
            //   title={
            //     img.name
            //     // img.name.title + ' ' + img.name.first + ' ' + img.name.last
            //   }
            //   // category={img.location.country}
            //   image={img.picture}
            //   // key={img.id}
            // />
          ))}
        </div>
      ) : (
        <div class="flex items-center justify-center ">
          <div class="w-16 h-16 border-b-2 border-logo-dark-color rounded-full animate-spin"></div>
        </div>
      )}
    </section>
  );
};

export default ProjectsGrid;
