import { useEffect, useState } from 'react';
import axios from 'axios';
import AppBanner from '../components/shared/AppBanner';
import ApiGrid from '../components/api/ApiGrid';
import { useTranslation } from 'react-i18next';
import SolventsAndAlcohols from '../images/alchol.png'
import Agrochemicals from '../images/agrochemical.jpeg'
import acids from '../images/asids.png'
// import nonImageForNow from '../images/chemctry.png'
import WaterTreatmentChemicals from '../images/water-treatment.jpeg'
import LeatherAndTextileTanningChemicals from '../images/leather.jpeg'
import ChemicalsForDetergentsAndDisinfectants from '../images/cleaners.jpeg'
import FeedAdditiveChemicals from '../images/cosmitecs.jpeg'
import ConcreteAdmixtureChemicals from '../images/concrete.png'
import LaboratoryChemicals from '../images/labrtoary.jpeg'
import FoodIndustryChemicals from '../images/food.png'
import SwimmingPoolChemicals from '../images/swamming-bool-treatment.png'
import CosmeticChemicals from '../images/makeup.jpeg'
import MetalProcessingChemicals from '../images/metal.jpeg'

const Home = () => {

  const { t, i18n } = useTranslation();
  const [data, setData] = useState([
  {name: 'جميع انواع الاحماض',dec: 'أحماض عضوية أحماض معدنية', picture: acids},
  {name: 'الكيماويات الخاصة بالمنظفات والمطهرات',dec: 'جميع الكيماويات الخاصة بالمنظفات والمطهرات', picture: ChemicalsForDetergentsAndDisinfectants},
  {name: 'الكيماويات بالتجميل',dec: 'الكيماويات الخاصة بمستحضرات التجميل', picture: CosmeticChemicals},
  {name: 'المذيبات و الكحولات',dec: 'جميع انواع المذيبات و الكحولات', picture: SolventsAndAlcohols},
  {name: 'كيماويات دباغة الجلود والنسيج',dec: 'جميع الكيماويات المستخدمة في صناعة دباغة الجلود والنسيج', picture: LeatherAndTextileTanningChemicals},
  {name: 'كيماويات اضافات الخرسانة',dec: 'كيماويات الإضافات الخرسانية بأنواعها المختلفة', picture: ConcreteAdmixtureChemicals},
  {name: 'كيماويات معالجة الماء',dec: 'جميع انواع الكيماويات المستخدمة في معالجة المياه', picture: WaterTreatmentChemicals},
  {name: 'كيماويات معالجة المعادن',dec: 'جميع كيماويات معالجة المعادن', picture: MetalProcessingChemicals},
  {name: 'كيماويات معمليه',dec: 'جميع الكيماويات المعمليه', picture: LaboratoryChemicals},
  {name: 'كيماويات الصناعات الغذائية',dec: 'جميع كيماويات الصناعات الغذائية', picture: FoodIndustryChemicals},
  {name: 'الكيماويات الزراعية',dec: 'جميع أنواع الكيماويات الزراعية', picture: Agrochemicals},
  {name: 'كيماويات اضافات الاعلاف',dec: 'جميع كيماويات اضافات الاعلاف', picture: FeedAdditiveChemicals},
  {name: 'كيماويات حمامات السباحة',dec: 'جميع كيماويات حمامات السباحة', picture: SwimmingPoolChemicals}

  ]);
  const [dataE, setDataE] = useState([
    // {name: 'translate', dec: '', picture: nonImageForNow},
    // {name: 'translate', dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow},
    // {name: 'translate',dec: '', picture: nonImageForNow}


    {name: 'All kinds Of Acids',dec: 'Organic acids mineral acids', picture:  acids},
   {name: 'Chemicals For Detergents And Disinfectants', dec: 'All chemicals for detergents and disinfectants', picture:  ChemicalsForDetergentsAndDisinfectants},
   {name: 'Cosmetic Chemicals',dec: 'Cosmetic chemicals', picture:  CosmeticChemicals},
   {name: 'Solvents And Alcohols',dec: 'All Kinds of Solvents and Alcohols', picture:  SolventsAndAlcohols},
   {name: 'Leather And Textile Tanning Chemicals', dec: 'All chemicals used in the leather and textile tanning industry', picture:  LeatherAndTextileTanningChemicals},
   {name: 'Concrete Admixture Chemicals',dec: 'Concrete admixture chemicals of various types', picture:  ConcreteAdmixtureChemicals},
   {name: 'Water Treatment Chemicals', dec: 'All kinds of chemicals used in water treatment', picture:  WaterTreatmentChemicals},
   {name: 'Metal Processing Chemicals',dec: 'All Metal Processing Chemicals', picture:  MetalProcessingChemicals},
   {name: 'Laboratory Chemicals',dec: 'All Laboratory Chemicals', picture:  LaboratoryChemicals},
   {name: 'Food Industry Chemicals',dec: 'All Food Industry Chemicals', picture:  FoodIndustryChemicals},
   {name: 'Agrochemicals',dec: 'All kinds of agrochemicals', picture:  Agrochemicals},
   {name: 'Feed Additive Chemicals',dec: 'All Feed Additive Chemicals', picture:  FeedAdditiveChemicals},
   {name: 'Swimming Pool Chemicals',dec: 'All swimming pool chemicals', picture:  SwimmingPoolChemicals}


    ]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // const asyncFunction = async () => {
    //   try {
    //     const firstResponse = await axios.get(
    //       'https://randomuser.me/api/?results=6',
    //       {
    //         params: {},
    //       }
    //     );
    //     console.log(`values`, firstResponse);
    //     setData(firstResponse.data.results);
    //     setLoading(false);
    //   } catch (error) {
    //     return error;
    //   }
    // };
    // asyncFunction();
  }, []);
  return (
    <>
      <AppBanner />
    <div className="container mx-auto">
      <div className="pt-5 sm:pt-30 pb-8 mt-5 border-t-2 border-primary-light dark:border-secondary-dark"></div>
      <div className={`${i18n.language === 'en' ? 'text-left' : 'text-right'}`}>
        <p className="font-general-medium text-2xl sm:text-4xl text-ternary-dark dark:text-ternary-light">
          {i18n.language === 'en' ? 'We Provide' : 'نقدم'}
        </p>
        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
      </div>
      <div className="grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-3 mt-4">
        <div className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-primary-light dark:bg-ternary-dark">
          <div className="p-4 flex items-center">
            <div
              className={`" p-3 rounded-full text-white bg-logo-dark-color dark:bg-logo-light-color ${
                i18n.language === 'en' ? 'mr-4' : 'ml-4'
              } "`}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm text-ternary-dark dark:text-ternary-light">
                {t('content.indicators.fLable')}
              </p>
              <p className="text-sm text-ternary-dark dark:text-ternary-light">
                {t('content.indicators.fValue')}
              </p>
            </div>
          </div>
        </div>
        <div className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-primary-light dark:bg-ternary-dark">
          <div className="p-4 flex items-center">
            <div
              className={`" p-3 rounded-full text-white dark:text-orange-100 bg-logo-dark-color dark:bg-logo-light-color ${
                i18n.language === 'en' ? 'mr-4' : 'ml-4'
              } "`}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path
                  fill-rule="evenodd"
                  d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm text-ternary-dark dark:text-ternary-light">
                {t('content.indicators.sLable')}
              </p>
              <p className="text-sm text-ternary-dark dark:text-ternary-light">
                {t('content.indicators.sValue')}
              </p>
            </div>
          </div>
        </div>
        <div className="min-w-0 rounded-lg shadow-xs overflow-hidden bg-primary-light dark:bg-ternary-dark">
          <div className="p-4 flex items-center">
            <div
              className={`" p-3 rounded-full text-white bg-logo-dark-color dark:bg-logo-light-color ${
                i18n.language === 'en' ? 'mr-4' : 'ml-4'
              } "`}
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm text-ternary-dark dark:text-ternary-light">
                {t('content.indicators.thLable')}
              </p>
              <p className="text-sm text-ternary-dark dark:text-ternary-light">
                {t('content.indicators.thValue')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ApiGrid data={i18n.language === 'en' ?dataE:data} loading={loading} />
    </div>
    </>
  );
};

export default Home;
