import Chart from '../components/chart/charts.js';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Imaagee from '../images/nilov.jpg';
const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, delay: 1 }}
        exit={{ opacity: 0 }}
        // className="container mx-auto"
      >
        {/* <div className="font-general-regular text-2xl font-semibold text-secondary-dark dark:text-secondary-light mb-1">
          {t('content.indicators.title')}
        </div> */}


<div className="container mx-auto">
    <div className={`grid grid-cols-1 md:grid-cols-2 h-screen ${
                i18n.language === 'ar' ? 'rounded-l-lg' : 'rounded-r-lg'
              } bg-primary-light dark:bg-ternary-dark`}>
      <div className="max-h-96 md:h-screen">
        <img className={`w-screen h-screen ${
                i18n.language === 'en' ? 'rounded-l-lg' : 'rounded-r-lg'
              } object-cover object-top opacity-30 md:opacity-100 `} src={Imaagee} alt={Imaagee}/>
      </div>
      <div className="contents md:flex text-ternary-dark dark:text-ternary-light p-10">
        <div className="mb-auto mt-auto max-w-lg">
          <h1 className="text-3xl uppercase">{t('content.mainTitle')}</h1>
          <p className="  font-semibold mb-5">{t('content.aboutDesc')}</p>
          <p>{t('content.aboutDescAll')}</p>
          {/* <button className="bg-logo-dark-color dark:bg-logo-light-color rounded-md py-3 px-7 mt-6 text-white">Email Me</button> */}
        </div>
      </div>
    </div>
  </div>

      </motion.div>
    </>
  );
};

export default About;



