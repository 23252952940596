import { useEffect, useState, createContext, useReducer } from 'react';

const GlobleStoreContext = createContext();

export const GlobleStoreProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.theme);
  const activeTheme = theme === 'dark' ? 'light' : 'dark';
  const initalState = {
    theme: theme,
    lang: 'ar',
  };

  const reducer = (state, action) => {
    switch (action) {
      case 'changeLang':
        const newLang = state.lang === 'ar' ? 'en' : 'ar';
        console.log('run run!! lan');
        return { ...state, lang: newLang };
      case 'changeTheme':
        console.log('run run!! theme');
        const activeTheme = state.theme === 'dark' ? 'light' : 'dark';
        return { ...state, theme: activeTheme };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initalState);
  console.log('run run!! reducer');

  // const [globleStore, setGlobleStore] = useState(store);

  //   const value = {
  //     state: state,
  //     changeLang: () => {
  //       dispatch({ type: 'changeLang' });
  //     },
  //     changeTheme: () => {
  //       dispatch({ type: 'changeTheme' });
  //     },
  //   };
  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove(activeTheme);
    root.classList.add(theme);
    localStorage.setItem('themeG', theme);
  }, [theme, activeTheme]);
  return (
    <GlobleStoreContext.Provider value={{ state, dispatch, setTheme, theme }}>
      {children}
    </GlobleStoreContext.Provider>
  );
};

export default GlobleStoreContext;
